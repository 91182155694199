import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { formatDate } from "../utils/utils";
import NewsOverview from "../components/NewsOverview";

const News = ({ data }) => {
  const { title, publishDate } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const news = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <h1 style={{
            marginBottom: "1rem"
          }}>{title}</h1>
          <p>{`Opublikowano ${formatDate(new Date(publishDate))}`}</p>
        </section>
        <section className="section inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="section-container section-alternative">
        <section className="section">
          <NewsOverview news={news.slice(0, 4)} title={"Pozostałe aktualności"} showRedirect={false} />
        </section>
      </div>

    </Layout>
  )
}

export default News;

export const pageQuery = graphql`
  query NewsByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        publishDate
      }
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "news"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 400)
              }
            }
        }
      }
    }
  }
}
`
